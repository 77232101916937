<template>
  <div
    ref="anchorEl"
    class="deck-dock-anchor"
    :style="[anchorStyle]"
    :class="{
      'deck-dock-anchor--vertical': isVertical,
    }"
  >
    <div
      ref="panelEl"
      class="deck-dock-panel"
      :class="{
        'deck-dock-panel--is-dragging': isDragging,
        [`deck-dock-panel--${state.position}`]: true,
      }"
      :style="panelStyle"
    >
      <div class="deck-dock-icon-button"  @pointerdown="onPointerDown">
        <deck-icon
          name="grip-vertical"
          kind="solid"
        />
      </div>
      <div class="deck-dock__container">
        <template
          v-for="(item, index) in items"
          :key="index"
        >
          <v-divider
            v-if="item.type === 'divider'"
            vertical
            :class="{
              'd-none d-md-flex': item.hideOnMobile,
              'my-2': true,
            }"
          />
          <deck-dock-item
            v-else
            :item="item"
            :dock-position="state.position"
            :size="size"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { mapMutations } from '~/assets/javascript/modules/vuex';
import { useElementSize, useEventListener, useLocalStorage } from '@vueuse/core';

defineOptions({ name: 'Dock' });

export interface DeckDockProps {
  items: Array<{
    icon?: string;
    text: string;
    imageUrl?: string;
    to?: string;
    onClick?:() => void;
    active?: boolean;
    items?: Array<{
      icon?: string;
      text: string;
      to?: string;
      onClick?: () => void;
    }>;
  }>;
}

defineProps<DeckDockProps>();

const { $vuetify } = useNuxtApp();

const size = computed(() => ($vuetify.display.mdAndUp.value ? 'large' : 'default'));

const state = useLocalStorage('zazos-deck-dock-state', {
  top: 0,
  left: 50,
  position: 'bottom',
});

const panelMargins = reactive({
  left: 16,
  top: 16,
  right: 16,
  bottom: 16,
});

const SNAP_THRESHOLD = 2;

const panelEl = useTemplateRef<HTMLDivElement>('panelEl');
const anchorEl = useTemplateRef<HTMLDivElement>('anchorEl');

const windowSize = reactive({ width: 0, height: 0 });
const isDragging = ref(false);
const draggingOffset = reactive({ x: 0, y: 0 });
const mousePosition = reactive({ x: 0, y: 0 });

function onPointerDown(e: PointerEvent) {
  if (!panelEl.value) return;
  isDragging.value = true;

  const { left, top, width, height } = panelEl.value!.getBoundingClientRect();
  draggingOffset.x = e.clientX - left - width / 2;
  draggingOffset.y = e.clientY - top - height / 2;
}

function snapToPoints(value: number) {
  if (value < 5) return 0;
  if (value > 95) return 100;
  if (Math.abs(value - 50) < SNAP_THRESHOLD) return 50;
  return value;
}

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

onMounted(() => {
  windowSize.width = window.innerWidth;
  windowSize.height = window.innerHeight;

  useEventListener(window, 'resize', () => {
    windowSize.width = window.innerWidth;
    windowSize.height = window.innerHeight;
  }, { passive: true });

  useEventListener(window, 'pointermove', (e: PointerEvent) => {
    if (!isDragging.value) return;

    const centerX = windowSize.width / 2;
    const centerY = windowSize.height / 2;

    const x = e.clientX - draggingOffset.x;
    const y = e.clientY - draggingOffset.y;

    mousePosition.x = x;
    mousePosition.y = y;

    // Get position
    const deg = Math.atan2(y - centerY, x - centerX);
    const HORIZONTAL_MARGIN = 70;
    const TL = Math.atan2(0 - centerY + HORIZONTAL_MARGIN, 0 - centerX);
    const TR = Math.atan2(0 - centerY + HORIZONTAL_MARGIN, windowSize.width - centerX);
    const BL = Math.atan2(windowSize.height - HORIZONTAL_MARGIN - centerY, 0 - centerX);
    const BR = Math.atan2(windowSize.height - HORIZONTAL_MARGIN - centerY, windowSize.width - centerX);

    if (deg >= TL && deg <= TR) {
      state.value.position = 'top';
    } else if (deg >= TR && deg <= BR) {
      state.value.position = 'right';
    } else if (deg >= BR && deg <= BL) {
      state.value.position = 'bottom';
    } else {
      state.value.position = 'left';
    }

    state.value.left = snapToPoints((x / windowSize.width) * 100);
    state.value.top = snapToPoints((y / windowSize.height) * 100);
  }, { passive: true });

  useEventListener(window, 'pointerup', () => {
    isDragging.value = false;
  }, { passive: true });

  useEventListener(window, 'pointerleave', () => {
    isDragging.value = false;
  }, { passive: true });
});

const isVertical = computed(() => state.value.position === 'left' || state.value.position === 'right');
const { width: panelWidth, height: panelHeight } = useElementSize(panelEl);

const anchorPos = computed(() => {
  const halfWidth = panelWidth.value / 2;
  const halfHeight = panelHeight.value / 2;
  const left = state.value.left * (windowSize.width / 100);
  const top = state.value.top * (windowSize.height / 100);

  switch (state.value.position) {
    case 'top':
      return {
        left: clamp(left, halfWidth + panelMargins.left, windowSize.width - halfWidth - panelMargins.right),
        top: panelMargins.top + halfHeight,
      };
    case 'right':
      return {
        left: windowSize.width - panelMargins.right - halfHeight,
        top: clamp(top, halfWidth + panelMargins.top, windowSize.height - halfWidth - panelMargins.bottom),
      };
    case 'left':
      return {
        left: panelMargins.left + halfHeight,
        top: clamp(top, halfWidth + panelMargins.top, windowSize.height - halfWidth - panelMargins.bottom),
      };
    case 'bottom':
    default:
      return {
        left: clamp(left, halfWidth + panelMargins.left, windowSize.width - halfWidth - panelMargins.right),
        top: windowSize.height - panelMargins.bottom - halfHeight,
      };
  }
});

const { setExtraBottomSpace: setAiChatExtraBottomSpace } = mapMutations('ai', ['setExtraBottomSpace']);

watch(anchorPos, () => {
  const isOverlapingAiChat = !isVertical.value && anchorEl.value.getBoundingClientRect().left - anchorEl.value.scrollWidth - 28 < 400;
  setAiChatExtraBottomSpace(isOverlapingAiChat);
});

const anchorStyle = computed(() => ({
  left: `${anchorPos.value.left}px`,
  top: `${anchorPos.value.top}px`,
  pointerEvents: 'auto',
} as const));

const panelStyle = computed(() => {
  const style: any = {
    transform: isVertical.value
      ? 'translate(-50%, -50%) rotate(90deg)'
      : 'translate(-50%, -50%)',
  };

  if (isDragging.value) {
    style.transition = 'none !important';
    style.cursor = 'grabbing';
  }

  return style;
});

</script>

<style lang="scss">
:root {
  --dock-drag-icon-width: 16px;
}

.deck-dock__container {
  backdrop-filter: blur(16px);
  background-color: rgba(var(--z-theme-surface-rgb), 0.65);
  border-radius: calc(var(--z-dock-base-radius) + 4px);
  border: 1px solid var(--z-theme-border-neutral);
  box-shadow: 0 var(--z-s1) var(--z-s4) 0 var(--z-theme-shadow) !important;
  display: flex;
  gap: var(--z-s2);
  padding: var(--z-s2);
  // Icon space
  margin-inline: calc(var(--z-s1) + var(--dock-drag-icon-width));
}

.deck-dock-icon-button {
  cursor: grab;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  height: 100%;

  .deck-dock-panel--right & { right: 0px; }
  .deck-dock-panel--left & { right: 0px; }
  .deck-dock-panel--top & { right: 0px; }
  .deck-dock-panel--bottom & { right: 0px; }

  .deck-dock-panel:hover & {
    opacity: 0.5;
  }

  .deck-dock-panel--is-dragging & {
    opacity: 1 !important;
  }
}

.deck-dock-anchor {
  width: 0;
  z-index: 2147483645;
  position: fixed;
  transform-origin: center center;
  transform: translate(-50%, -50%) rotate(0);
}

.deck-dock-anchor .deck-dock-panel {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  padding: 2px 2px 2px 2.5px;
  user-select: none;
  touch-action: none;
  transition:
    all 0.6s ease,
    max-width 0.6s ease,
    padding 0.5s ease,
    transform 0.4s ease,
    opacity 0.2s ease;
}

@media print {
  .deck-dock-anchor {
    display: none;
  }
}
</style>
